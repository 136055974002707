<template>
  <div>
    <el-table
      :border="true"
      :data="
        rows.filter(
          (data) =>
            !search ||
            data.fullName.toLowerCase().includes(search.toLowerCase()),
        )
      "
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column type="selection" width="55"></el-table-column>

      <el-table-column
        :label="fields.avatars.label"
        :prop="fields.avatars.name"
        :align="center"
        width="70px"
      >
        <template v-slot="scope">
          <app-list-item-image
            :value="presenter(scope.row, 'avatars')"
          ></app-list-item-image>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.email.label"
        :prop="fields.email.name"
        sortable="custom"
      >
        <template v-slot="scope">{{ presenter(scope.row, 'email') }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.fullName.label"
        :prop="fields.fullName.name"
        sortable="custom"
      >
        <template v-slot="scope">{{
          presenter(scope.row, 'fullName')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.faculty.label"
        :prop="fields.faculty.name"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-many
            :label="fields.faculty.label"
            :readPermission="fields.faculty.readPermission"
            :navigatePermission="fields.faculty.navigatePermission"
            :url="fields.faculty.viewUrl"
            :value="presenter(scope.row, 'faculty')"
          ></app-list-item-relation-to-many>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.location.label"
        :prop="fields.location.name"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-many
            :label="fields.location.label"
            :readPermission="fields.location.readPermission"
            :navigatePermission="fields.location.navigatePermission"
            :url="fields.location.viewUrl"
            :value="presenter(scope.row, 'location')"
          ></app-list-item-relation-to-many>
        </template>
      </el-table-column>

      <el-table-column :label="fields.roles.label" :prop="fields.roles.name">
        <template v-slot="scope">
          <div :key="roleId" v-for="roleId in scope.row.roles">
            <el-tooltip :content="roleDescriptionOf(roleId)">
              <span>{{ roleLabelOf(roleId) }}</span>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.status.label"
        :prop="fields.status.name"
        sortable="custom"
      >
        <template v-slot="scope">
          <el-tag :type="getStatusTagType(scope.row[fields.status.name])">{{
            presenter(scope.row, 'status')
          }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.createdAt.label"
        :prop="fields.createdAt.name"
        sortable="custom"
      >
        <template v-slot="scope">{{
          presenter(scope.row, 'createdAt')
        }}</template>
      </el-table-column>

      <el-table-column :fixed="isMobile ? undefined : 'right'" :align="center">
        <template slot="header">
          <el-input v-model="search" size="mini" placeholder="Type to search" />
        </template>
        <template v-slot="scope">
          <div class="table-actions">
            <router-link :to="`/iam/${scope.row.id}`">
              <el-button type="text">
                <app-i18n code="common.view"></app-i18n>
              </el-button>
            </router-link>

            <div v-if="hasPermissionToEdit">
              <router-link :to="`/iam/${scope.row.id}/approve`">
                <el-button
                  type="text"
                  :disabled="scope.row[fields.status.name] != 'releasePending'"
                >
                  <app-i18n code="common.approve"></app-i18n>
                </el-button>
              </router-link>

              <el-button
                type="text"
                @click="doOpenModal(scope.row)"
                v-if="hasPermissionToEdit"
              >
                <app-i18n code="common.changePassword"></app-i18n>
              </el-button>

              <router-link
                :to="`/iam/${scope.row.id}/edit`"
                v-if="hasPermissionToEdit"
              >
                <el-button type="text">
                  <app-i18n code="common.edit"></app-i18n>
                </el-button>
              </router-link>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
    <portal to="modal">
      <app-iam-password-change
        :visible="dialogVisible"
        :userId="userId"
        v-if="dialogVisible"
        @success="onModalSuccess"
        @close="onModalClose"
      ></app-iam-password-change>
    </portal>
  </div>
</template>

<script>
import { UserModel } from '@/modules/auth/user-model';
import { mapGetters, mapActions } from 'vuex';
import { IamPermissions } from '@/modules/iam/iam-permissions';
import IamPasswordChangeModal from '@/modules/iam/components/iam-password-change';
import Roles from '@/security/roles';

const { fields } = UserModel;

export default {
  name: 'app-iam-list-table',

  components: {
    [IamPasswordChangeModal.name]: IamPasswordChangeModal,
  },

  data() {
    return {
      search: '',
      dialogVisible: false,
      record: null,
    };
  },

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  /*   watch: {
    // is this step necessary?
    // or should I use computed properties?
    dataFetched(value) {
      if (value) this.doMountTable(this.$refs.table);
    },
  }, */

  computed: {
    ...mapGetters({
      rows: 'iam/list/rows',
      count: 'iam/list/count',
      loading: 'iam/list/loading',
      pagination: 'iam/list/pagination',
      dataFetched: 'iam/list/dataFetched',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new IamPermissions(this.currentUser).edit;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'iam/list/doChangeSort',
      doChangePaginationCurrentPage: 'iam/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'iam/list/doChangePaginationPageSize',
      doMountTable: 'iam/list/doMountTable',
    }),

    roleDescriptionOf(roleId) {
      return Roles.descriptionOf(roleId);
    },

    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },

    presenter(row, fieldName) {
      return UserModel.presenter(row, fieldName);
    },

    getStatusTagType(status) {
      if (status === 'enabled') {
        return 'success';
      }
      if (status === 'disabled') {
        return 'danger';
      }
      if (status === 'releasePending') {
        return 'warning';
      }
    },

    doOpenModal(payload) {
      this.userId = payload.id;
      this.dialogVisible = true;
    },

    onModalSuccess() {
      this.dialogVisible = false;
    },

    onModalClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style></style>
